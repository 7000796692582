<template>
  <div class="modal__qualificacao">
    <b-modal
      no-close-on-backdrop
      v-model="modalShow"
      hide-footer
      header
      hide-header
      modal-class="bg-transparent"
      static
    >
      <b-col v-if="lead">
        <div class="row">
          <div class="col-lg-12 grid-margin stretch-card">
            <b-card>
              <template #header>
                <h6 class="mb-0">
                  <i class="mdi mdi-phone mr-1"></i> Qualificação 
                </h6>
              </template>
              <b-card-body class="py-0">
                <div class="row" v-if="contatoData">
                  <div class="w-50">
                    <dl>
                      <dt>Nome</dt>
                      <dd class="text-primary">{{ contatoData.nome }}</dd>
                    </dl>
                  </div>
                  <div class="w-50" v-if="contatoData.empresa">
                    <dl>
                      <dt>Empresa</dt>
                      <dd>
                        <span class="text-wrap text-primary">
                          {{ contatoData.empresa.nome_fantasia }}</span
                        >
                      </dd>
                    </dl>
                  </div>
                </div>

                <form class="forms-sample" @submit.stop.prevent="saveOrUpdate">
                  <b-form-group label="Status">
                    <multiselect
                      v-model="lead.lead_status"
                      :options="leadStatus"
                      :multiple="false"
                      placeholder="Selecionar Status"
                      label="nome"
                      track-by="id"
                      selectLabel="Enter para selecionar"
                      selectedLabel="Selecionado"
                      deselectLabel="Enter para remover"
                    >
                      <template slot="noOptions"
                        >Digite para pesquisar.</template
                      >
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <i
                            v-if="props.option.lead_status_tipo_id == 1"
                            class="icon-check text-success"
                          ></i>
                          <i
                            v-if="props.option.lead_status_tipo_id == 2"
                            class="icon-close text-danger"
                          ></i>
                          <i
                            v-if="props.option.lead_status_tipo_id == 3"
                            class="icon-clock text-warning"
                          ></i>
                          <span class="option__title ml-1">{{
                            props.option.nome
                          }}</span>
                        </div>
                      </template>
                    </multiselect>
                  </b-form-group>

                  <!-- Campos Recusa -->
                  <template
                    v-if="
                      lead.lead_status &&
                      lead.lead_status.lead_status_tipo_id == 2
                    "
                  >
                    <b-form-group label="Status">
                      <multiselect
                        v-model="lead.lead_recusa"
                        :options="leadRecusas"
                        :multiple="false"
                        placeholder="Selecionar Motivo de Recusa"
                        label="nome"
                        track-by="id"
                        selectLabel="Enter para selecionar"
                        selectedLabel="Selecionado"
                        deselectLabel="Enter para remover"
                      >
                        <template slot="noOptions"
                          >Digite para pesquisar.</template
                        >
                      </multiselect>
                    </b-form-group>
                    <!-- Notas Recusa -->
                    <b-form-group label-for="notasRecusaInput">
                      <template slot="label"
                        >Notas da Recusa
                        <i
                          class="mdi mdi-information-outline"
                          v-b-tooltip.hover
                          title="Descreva motivos da recusa."
                        ></i
                      ></template>
                      <b-input-group size="md">
                        <b-form-textarea
                          v-model="lead.recusa_notas"
                          id="notasRecusaInput"
                          rows="5"
                          max-rows="10"
                        ></b-form-textarea>
                      </b-input-group>
                    </b-form-group>
                  </template>

                  <!-- Notas -->
                  <b-form-group v-else label-for="notasInput">
                    <template slot="label"
                      >Notas
                      <i
                        class="mdi mdi-information-outline"
                        v-b-tooltip.hover
                        title="Descreva BANT da oportunidade ou o que ocorreu na atividade realizada."
                      ></i
                    ></template>
                    <b-input-group size="md">
                      <b-form-textarea
                        v-model="lead.notas"
                        id="notasInput"
                        rows="5"
                        max-rows="10"
                      ></b-form-textarea>
                    </b-input-group>
                  </b-form-group>

                  <!-- lead.lead_status -->
                  <div
                    v-if="
                      lead.lead_status &&
                      lead.lead_status.lead_status_tipo_id == 1
                    "
                  >
                    <b-button
                    @click="saveAndCreateOpportunity"
                     variant="success" class="font-size-14 w-100">
                      

                      <template v-if="loadingOpportinity">
                          <b-spinner small type="grow"></b-spinner>
                          Processando...
                        </template>
                        <template v-else> Salvar e Criar Oportunidade</template>



                    </b-button>

                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <b-button
                        type="submit"
                        class="my-2 mr-2 w-50 font-size-14"
                        :disabled="loading"
                      >
                        <template v-if="loading">
                          <b-spinner small type="grow"></b-spinner>
                          Processando...
                        </template>
                        <template v-else> Salvar e Fechar </template>
                      </b-button>
                      <b-button @click="modalShow = !modalShow" class="w-50"
                        >Cancelar</b-button
                      >
                    </div>
                  </div>

                  <div class="d-flex" v-else>
                    <b-button
                      type="submit"
                      variant="success"
                      class="mr-2"
                      :disabled="loading"
                    >
                      <template v-if="loading">
                        <b-spinner small type="grow"></b-spinner>
                        Processando...
                      </template>
                      <template v-else> Salvar </template>
                    </b-button>

                    <b-button @click="modalShow = !modalShow"
                      >Cancelar</b-button
                    >
                  </div>
                </form>
                <div
                  class="d-flex justify-content-between border-top mt-4 pt-4"
                >
                  <div class="d-flex">
                    <div class="dot-indicator bg-primary mt-1 mr-2"></div>
                    <div class="d-flex flex-column">
                      <p class="mb-0 text-muted">Tentativas</p>
                      <small class="text-gray">{{ lead.tentativas }}</small>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </div>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import ContatoService from "../../../services/contato.service";
import LeadService from "../../../services/lead.service";
import LeadRecusaService from "../../../services/leadrecusa.service";
import LeadStatusService from "../../../services/leadstatus.service";

// import PerguntasService from "../../../services/perguntas.services";

export default {
  props: ["fetchDataView"],
  data() {
    return {
      lead: null,
      contato: null,
      modalShow: false,
      leadStatus: [],
      loading: false,
      // loadingOpportinity: false,
      loadingOpportinity: false,
      leadRecusas: [],
    };
  },

  mounted() {
    this.modalShow = this.showModalQualificacao; // required
    this.fetchData();

    // console.log(this.contatoData);
    /*
    PerguntasService.getAll()
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

      */
  },

  created() {
    document.querySelector("html").style = "overflow-y: hidden";
  },

  methods: {
    ...mapMutations(["setShowModalQualificacao", "setContatoData", "setShowModalNovaOportunidade"]),
    fetchData() {
      this.lead = null;
      this.loading = true;

      // Carregar dados do Lead
      LeadService.get(this.idLead)
        .then((response) => {
          this.lead = response.data;

          // console.log(this.lead);

          this.contato = null;

          // console.log(response.data);

          // Carregar dados do Contato
          ContatoService.get(this.lead.contato_id).then((response) => {
            // console.log(response);
            this.contato = response.data;
          });

          //Carregar Status do Projeto
          let params = {};
          if (this.projetoSelecionado) {
            params.projeto_id = this.projetoSelecionado.id;
          } else if (this.lead.projeto_id) {
            params.projeto_id = this.lead.projeto_id;
          } else {
            this.$router.push({ name: "Leads" });
          }
          LeadStatusService.getAll(params).then((response) => {
            this.leadStatus = response.data;
          });

          //Carregar Motivos de Recusa do Projeto
          LeadRecusaService.getAll(params).then((response) => {
            this.leadRecusas = response.data;
          });
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },

    saveOrUpdate() {
      this.loading = true;

      if (!this.lead.lead_status) {
        this.loading = false;
        return;
      }

      this.lead.lead_status_id = this.lead.lead_status
        ? this.lead.lead_status.id
        : null;
      this.lead.lead_recusa_id = this.lead.lead_recusa
        ? this.lead.lead_recusa.id
        : null;

      const promise = LeadService.saveOrUpdate(this.lead);
      return promise
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "Sucesso!",
            autoHideDelay: 1000,
            toaster: "b-toaster-bottom-left",
            variant: "success",
          });

          // this.fetchData();
          this.setShowModalQualificacao(false);
          this.fetchDataView();
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },

    // saveAndCreateOportunit(){

    // },
    saveAndCreateOpportunity(){
      this.loadingOpportinity = true;

      if (!this.lead.lead_status) {
        this.loadingOpportinity = false;
        return;
      }

      this.lead.lead_status_id = this.lead.lead_status
        ? this.lead.lead_status.id
        : null;
      this.lead.lead_recusa_id = this.lead.lead_recusa
        ? this.lead.lead_recusa.id
        : null;

      const promise = LeadService.saveOrUpdate(this.lead);
      return promise
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "Sucesso!",
            autoHideDelay: 1000,
            toaster: "b-toaster-bottom-left",
            variant: "success",
          });
          
            // nao alterar essa ordem
            this.setShowModalNovaOportunidade(true);
            this.setShowModalQualificacao(false);
            this.fetchDataView();






        })
        .catch(() => {})
        .then(() => {
          this.loadingOpportinity = false;
        });
        

    



    }
  },
  computed: {
    ...mapState({
      showModalQualificacao: (state) => state.leads.showModalQualificacao,
      idLead: (state) => state.leads.idLead,
      contatoData: (state) => state.leads.contatoData,
    }),
  },
  watch: {
    modalShow() {
      this.setShowModalQualificacao(this.modalShow);
    },
  },

  beforeDestroy() {
    this.setContatoData(undefined);
    document.querySelector("html").style = "overflow-y: auto";
  },
};
</script>

<style lang="scss">
.bg-transparent {
  .modal-content {
    background-color: transparent !important;
    border: 0;
  }
}


.modal__qualificacao{
  .modal-content, .white--mode .swal2-popup{
        max-width: inherit !important; 
      padding: 0 !important;
        box-shadow: none;
}
}
</style>