import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + "/contatos";
const API_BASE = process.env.VUE_APP_API_URL;

class ContatoService {

    getAll(params) {
        return axios.post(API_URL + '/search', params, {
            headers: authHeader()
        });
    }

    get(id) {
        return axios.get(API_URL + '/' + id, { headers: authHeader() });
    }

    download(format, params) {
        return axios.post(API_URL + '/download/' + format, params, {
            responseType: 'arraybuffer',
            headers: authHeader()
        });
    }

    saveOrUpdate(data, id) {
        if (!id) {
            return axios.post(API_URL,
                data,
                { headers: authHeader() }

            )
        } else {
            return axios.put(`${API_URL}/${id}`,
                data,
                { headers: authHeader() }

            )
        }

    }

    delete(id) {
        return axios.delete(`${API_URL}/${id}`, { headers: authHeader() })
    }


    getPhones(params) {
        return axios.get(`${API_BASE}/contatoTelefones`, {
            params: params,
            headers: authHeader()
        });
    }

    addPhones(data) {

        return axios.post(`${API_BASE}/contatoTelefones`,
            data,
            { headers: authHeader() }

        )
    }

    deletePhone(id) {
        return axios.delete(`${API_BASE}/contatoTelefones/${id}`, { headers: authHeader() })
    }

    // Emails
    getEmails(params) {

        return axios.get(`${API_BASE}/contatoEmails`, {
            params: params,
            headers: authHeader()
        });

    }

    addEmails(data) {

        return axios.post(`${API_BASE}/contatoEmails`,
            data,
            { headers: authHeader() }

        )

    }

    deleteEmail(id) {
        return axios.delete(`${API_BASE}/contatoEmails/${id}`, { headers: authHeader() })

    }

    totalContatos(params) {
        return axios.post(API_URL + '/totalContatos', params, {
            headers: authHeader()
        });
    }

    total(params) {
        return axios.post(API_URL + '/total', params, {
            headers: authHeader()
        });
    }




}

export default new ContatoService();
