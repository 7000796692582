<template>
  <b-modal
    :content-class="
      !this.$store.state.showWhiteMode
        ? 'modallEditarStatus h-100'
        : 'modallEditarStatusWhite h-100'
    "
    static
    class="modal__nova__oportunidade"
    no-close-on-backdrop
    hide-footer
    no-close-on-esc
    v-model="modalShow"
    size="xl"
    title="Criar Oportunidade"
    scrollable
  >
    <div v-if="contato && questions.length > 0">
      <div class="row" >
        <div class="col-4">
          <dl class="pl-0">
            <dt>Nome</dt>
            <dd class="text-primary">{{ contato.nome }}</dd>
          </dl>
        </div>
        <div
          class="col-4"
          v-if="contato.empresa && contato.empresa.nome_fantasia"
        >
          <dl class="pl-0">
            <dt>Empresa</dt>
            <dd>
              <span class="text-wrap text-primary"
                >{{ contato.empresa.nome_fantasia }}
              </span>
            </dd>
          </dl>
        </div>

        <div class="col-4" v-if="projetoSelecionado">
          <dl class="pl-0">
            <dt>Projeto</dt>
            <dd>
              <span
                class="text-wrap text-primary"
                v-if="projetoSelecionado.nome"
                >{{ projetoSelecionado.nome }}
              </span>
            </dd>
          </dl>
        </div>
      </div>

      <div class="d-flex flex-wrap card-body" v-if="questions.length > 0">
        <div class="w-50">
          <b-form-group
            v-for="question in questions"
            :key="question.id + question.created_at"
            :label="question.descricao"
            :label-for="question.id.toString()"
          >
            <b-form-input
              :id="question.id.toString()"
              placeholder="Resposta"
            ></b-form-input>
          </b-form-group>
        </div>

        <!-- Data de reunião -->
        <div class="w-50 px-5">
          <b-form-group label="Data de reunião">
            <v-date-picker v-model="date" mode="dateTime" is24hr>
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="
                    px-2
                    py-1
                    border
                    rounded
                    focus:outline-none focus:border-blue-300
                    w-100
                  "
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </b-form-group>

          <!-- <h6 class="card-title">Data de reunião</h6> -->

          <!-- <div>

            <v-date-picker v-model="date" mode="dateTime" is24hr>
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="
                    px-2
                    py-1
                    border
                    rounded
                    focus:outline-none focus:border-blue-300
                  "
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div> -->

          <div role="group" class="form-group">
            <label for="notas" class="d-block">Notas</label>
            <div>
              <textarea
                id="notas"
                placeholder="Notas"
                wrap="soft"
                class="form-control"
                style="resize: none; overflow-y: scroll; height: 100px"
              ></textarea
              ><!----><!----><!---->
            </div>
          </div>
        </div>
      </div>
      




    </div>

    <div v-if="loading" class="d-flex align-items-center justify-content-center h-100">
      <b-spinner
        variant="primary"
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
    </div>


    <div v-if="!loading && questions.length == 0" class="text-center">
        <span class="text-muted">Não há Questionários </span>
        
    </div>





    <div class="w-100 text-right">
      <b-button variant="secondary" @click="setShowModalNovaOportunidade(false)"
        >Sair</b-button
      >
      <b-button variant="primary ml-2" v-if="questions.length > 0">Salvar</b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import ContatoService from "../../../services/contato.service";
import LeadService from "../../../services/lead.service";
import LeadRecusaService from "../../../services/leadrecusa.service";
import LeadStatusService from "../../../services/leadstatus.service";

import PerguntasService from "../../../services/perguntas.services";

export default {
  props: ["fetchDataView"],
  data() {
    return {
      lead: null,
      contato: null,
      modalShow: false,
      leadStatus: [],
      loading: true,
      leadRecusas: [],
      questions: [],
      date: new Date(),
      timezone: "",
    };
  },

  mounted() {
    this.fetchData();
    this.modalShow = this.showModalNovaOportunidade; // required
    // console.log("mounted ", this.idLead);
    document.querySelector("html").style = "overflow-y: hidden";

  },

  created() {
    document.querySelector("html").style = "overflow-y: hidden";
  },

  methods: {
    ...mapMutations(["setShowModalNovaOportunidade"]),
    fetchData() {
      this.lead = null;
      this.loading = true;

      LeadService.get(this.idLead)
        // LeadService.get(236)
        .then((response) => {
          this.lead = response.data;

          this.contato = null;

          // console.log(response.data);

          const projeto_id = response.data.projeto_id;

          // console.log(projeto_id);

          if (projeto_id) {
            this.fetchQuestions(projeto_id);
          }

          // Carregar dados do Contato
          ContatoService.get(this.lead.contato_id).then((response) => {
            // console.log(response.data);
            this.contato = response.data;
          });

          //Carregar Status do Projeto
          let params = {};
          if (this.projetoSelecionado) {
            params.projeto_id = this.projetoSelecionado.id;
          } else if (this.lead.projeto_id) {
            params.projeto_id = this.lead.projeto_id;
          } else {
            this.$router.push({ name: "Leads" });
          }
          LeadStatusService.getAll(params).then((response) => {
            this.leadStatus = response.data;
          });

          //Carregar Motivos de Recusa do Projeto
          LeadRecusaService.getAll(params).then((response) => {
            this.leadRecusas = response.data;
          });
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },

    fetchQuestions(projeto_id) {
      PerguntasService.get(projeto_id)
        .then((res) => {
          this.questions = res.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;

        });
    },
  },
  computed: {
    ...mapState(["projetoSelecionado"]),
    ...mapState({
      showModalNovaOportunidade: (state) =>
        state.leads.showModalNovaOportunidade,
      idLead: (state) => state.leads.idLead,
    }),
  },
  watch: {
    modalShow() {
      this.setShowModalNovaOportunidade(this.modalShow);
    },

   
  
  },

  beforeDestroy() {
    document.querySelector("html").style = "overflow-y: auto";
  },
};
</script>

<style lang="scss">



</style>