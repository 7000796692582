import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.VUE_APP_API_URL + "/projetoPerguntas";




export default {

    get(projeto_id) {
        return axios.get(API_URL,  { 
            headers: authHeader() ,
            params: {projeto_id: projeto_id}, //248

        });
    }

}



 